import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import { Section, Container, Row, Column, Heading } from '../components/ui';
import { Button, WaveDivider, FullwidthCTA, Layout, SEO } from '../components';
import {
    DisputeSection,
    ServicesSection,
} from '../components/section_components';
import { breakpoints } from '../utils/screenSize';

const Page = (props: RouteComponentProps) => {
    const data = useStaticQuery(graphql`
        query Images_v2_1_refactor {
            phonesLg: file(relativePath: { eq: "phone-preview-screen.png" }) {
                publicURL
            }
            phonesSm: file(
                relativePath: { eq: "phone-preview-screen-sm.png" }
            ) {
                publicURL
            }
        }
    `);

    return (
        <Layout>
            <SEO
                title="Get the Tools to Increase Your Credit Scores | ScoreShuttle"
                description="Access your free Experian credit report and score today! Use ScoreShuttle to dispute report errors and get the know-how needed to improve your credit, digitally."
            />
            <Section className="bg-ss-blue-900 pt-24 lg:pt-0">
                <Container className="pb-0 lg:pb-10">
                    <Row className="flex flex-wrap lg:flex-no-wrap">
                        <Column className="text-white text-center lg:text-left flex-auto lg:w-auto pr-2 relative z-10">
                            <div className="container-narrow">
                                <Heading
                                    headingLevel={1}
                                    text="Your mission to a higher credit score starts here."
                                    className="lg:text-left lg:mx-0"
                                />
                                <div className="inline-block text-left">
                                    <p className="font-heading text-xl sm:text-2xl md:text-3xl text-left mb-2">
                                        Start for free<span>*</span> to get:
                                    </p>
                                    <ul className="font-light lg:font-hairline text-left leading-relaxed list-disc pl-5 mb-10 sm:text-lg lg:text-xl">
                                        {[
                                            'FREE 1-Bureau Credit Report and Score',
                                            'FREE ScoreShuttle Credit Report Analysis',
                                            'FREE Score-Boosting Secrets in Credit Camp',
                                        ].map((listItem, index) => (
                                            <li key={index}>{listItem}</li>
                                        ))}
                                    </ul>
                                </div>
                                <Button
                                    theme="blue"
                                    onClick={() =>
                                        window.open(
                                            'https://app.scoreshuttle.com/setup'
                                        )
                                    }
                                >
                                    GET MY FREE CREDIT REPORT AND SCORE
                                </Button>
                                <div className="text-sm mt-8">
                                    *
                                    <a
                                        href="https://www.scoreshuttle.com/pricing/"
                                        target="_blank"
                                    >
                                        View all pricing options
                                    </a>
                                </div>
                            </div>
                        </Column>
                        <Column className="mt-lg:max-w-1/2 lg:w-auto relative z-10">
                            <picture>
                                <source
                                    media={`(min-width: ${breakpoints.lg}px)`}
                                    srcSet={data.phonesLg.publicURL}
                                />
                                <img
                                    className="mx-auto mt-20 lg:-mt-2"
                                    src={data.phonesSm.publicURL}
                                    alt="cell phones displaying ScoreShuttle web pages"
                                />
                            </picture>
                        </Column>
                    </Row>
                </Container>
                <WaveDivider
                    foregroundColor="bg-white"
                    backgroundColor="text-ss-blue-900"
                    flip="y"
                    inset="bottom"
                />
            </Section>
            <DisputeSection />
            <ServicesSection />
            <FullwidthCTA />
        </Layout>
    );
};

export default Page;
